import './main.css'
import landscape0 from './../../../src/images/landscape0.jpg'

function Main() {
    return (
        <main>
            <div className='landscape0'>
                <img srcSet={`${landscape0} 1920w`} alt="Something went wrong.." />
            </div>
        </main>
    )
}

export default Main;