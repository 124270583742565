import { t } from 'i18next';
import React from 'react';
import './NationsTable.css';
import { useTranslation } from "react-i18next";

const createWordTable = (words, rows, cols) => {
    const tableRows = Array.from({ length: rows }, (_, rowIndex) => {
        const cells = Array.from({ length: cols }, (_, colIndex) => {
            // Вычисляем индекс слова на основе текущего столбца и строки
            const wordIndex = colIndex * rows + rowIndex;
            const cellContent = wordIndex < words.length ? words[wordIndex] : '';

            return (
                <td key={colIndex}>
                    {cellContent}
                </td>
            );
        });

        return <tr key={rowIndex}>{cells}</tr>;
    });

    return <tbody>{tableRows}</tbody>;
};

const WordTable = ({ rows, cols, nations }) => {
    const wordsString = t(nations);

    // Разбиваем строку на массив слов
    const words = wordsString.split(',');

    return (
        <table className='columns'>
            {createWordTable(words, rows, cols)}
        </table>
    );
};

export default WordTable;