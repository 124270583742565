// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
        //footer
        Народы: "Peoples",
        Интерьвью: "Interviews",
        Природа: "Nature",
        Проблемы: "Problems",
        Помочь: "Help",
        
        //section1
        about_us: "About us",
        we_care_about: "We are concerned about the environmental changes happening on our planet:",
        climate_change: "climate change, decrease in biodiversity, threats to unique plants",
        and_animals: "and animals",
        recently_learned: "Recently, we have learned a lot about the indigenous peoples of Russia and how",
        relationships_with_nature: "they build relationships with the surrounding world",

        //section2
        our_mission: "Our Mission",
        mission_statement_1: "The mission of this project is to draw public attention to the indigenous peoples",
        mission_statement_2: "of Russia, their ecological way of life, and practices for preserving the environment with",
        mission_statement_3: "the aim of adopting their ways of interacting with nature to improve",
        mission_statement_4: "the ecological condition of our planet.",
        
        //section3
        indigenous_small_numbered_people_definition: "According to Russian legislation, an indigenous small-numbered people is one that is settled in the territory of their ancestors, inherits traditional methods of management, and whose population does not exceed 50,000 people",
        learn_more: "Learn more",
        game_description: "During the game of kheer shaaglan, a participant must break the spine bone of a bull or cow with the palm. To be able to break the bone, a special technique is used: several trial strikes are made to 'feel' the bone, and then the participant delivers the final, most powerful blow with the back of the palm. This game is part of the traditional culture of the Buryats.",
        game_description_mobile: "During the game of kheer shaaglan, a participant must break the spine bone of a bull or cow with the palm. To be able to break the bone, a special technique is used. This game is part of the traditional culture of the Buryats.",
        indigenous_peoples_challenges: "The difficulty of preserving the language, the disappearance of ancient traditions, threats to national cultures, destruction of ecosystems - these are the problems faced by indigenous peoples not only in Russia but also in other countries. In 1992, the UN adopted the 'Declaration on the Rights of Persons Belonging to National or Ethnic, Religious and Linguistic Minorities', securing the rights of minorities in multi-ethnic states.",
        indigenous_peoples_challenges_mobile: "In 1992, the UN adopted the 'Declaration on the Rights of Persons Belonging to National or Ethnic, Religious and Linguistic Minorities', securing the rights of minorities in multi-ethnic states.",
        // indigenous_teaching_quote1: "The indigenous peoples of Russia teach us to live in harmony with nature,",
        // indigenous_teaching_quote2: "showing respect for the land and sustainability for future",
        // indigenous_teaching_quote3: "generations.",
        indigenous_teaching_quote0: "The indigenous peoples of Russia teach us to live in harmony with nature, showing respect for the land and sustainability for future generations.",
        author_arseny_karsakov: "Arseny Karsakov",
        our_policy_title: "Our Policy",
        culture_preservation_title: "Culture Preservation",
        culture_preservation_description: "We strive to raise awareness about the indigenous peoples of Russia and their ecological practices, respecting their traditions and rights. Our task is to spread knowledge about sustainable interaction with nature, based on the voices and perspectives of these peoples.",
        educational_interaction_title: "Educational Interaction",
        educational_interaction_description: "Through an interactive quiz, we stimulate the study of the ecological practices of indigenous peoples, fostering a deep understanding and respect for their cultures. We consider education to be the key to ecological sustainability.",
        website_information_title: "Website Information",
        website_information_description: "Created to highlight the lives of indigenous peoples, our website is committed to providing current and respectful information that reflects the latest research. We aim to improve the environmental situation by expanding knowledge of indigenous cultures and practices.",

        //section4
        section4_quote: "Nature can do without man, but not he without it.",
        section4_author: "Ali Apsheron",
        section4_quote_mobile_1: "Nature can do without man,",
        section4_quote_mobile_2: "but not he without it.",

        //section5
        help_title: "You can help",
        help_message: "Protect the people and places you love. Make your contribution today.",
        help_button: "yes - I want to help!",
        help_button_mobile: "Want to help!",


        //footer
        footer_write_to_us: "Write to us",
        footer_email: "russianative@gmail.com",
        footer_description: "We have created a useful site where we have collected information about the indigenous peoples of our country, their problems, and ways of interacting with the environment that are not only effective but also safe for nature.",
        footer_copyright: "© 2024 Indigenous People, Website by safroalex Team",
        footer_nations_nature: "peoples and nature",
        footer_learn_more: "learn more",
        footer_nations_interview: "peoples and interview",

        //sliderAndQuiz
        check_your_knowledge: 'check your knowledge',
        correct_answers_count: 'Number of correct answers',
        correct_answers_count_from: 'out of',
        test_completed: 'Test completed',

        fact_correct_answer: 'Yes, this is the correct answer.',
        fact_wrong_answer: 'No, this is the wrong answer.',
        fact_indigenous_peoples_count: 'According to the "Unified list of indigenous small-numbered peoples of the Russian Federation", Russia is home to 47 indigenous small-numbered peoples.',
        fact_kereks_population: 'Kereks. During the 2021 All-Russian population census, only 4 people identified themselves as Kereks. This is the correct answer.',

        quiz_question1: 'Question 1',
        quiz_question1_text: 'Do you know how many indigenous small-numbered peoples live in Russia?',
        quiz_question1_option1: 'MORE THAN 50',
        quiz_question1_option2: '193',
        quiz_question1_option3: '47',
        quiz_question1_option4: '29',

        quiz_question2: 'Question 2',
        quiz_question2_text: 'According to recent studies, which people is now down to just 4 individuals?',
        quiz_question2_option1: 'KEREKS',
        quiz_question2_option2: 'YUKAGHIRS',
        quiz_question2_option3: 'ENETS',
        quiz_question2_option4: 'OROCHS',
        
        quiz_question3: 'Question 3',
        quiz_question3_text: 'Vargan, komus, khomus, komys, amanhuur, tumran, zubanka - all these names refer to one important item in the cultural life of the northern peoples. Did you guess what it is?',
        quiz_question3_option1: 'MUSICAL INSTRUMENT',
        quiz_question3_option2: 'BABY CRADLE',
        quiz_question3_option3: 'SMOKING PIPE',
        quiz_question3_option4: 'SPECIAL CEREMONIAL KNIFE',
        
        quiz_question4: 'Question 4',
        quiz_question4_text: 'Representatives of this people live on two continents - Eurasia and North America. The traditional dwelling "valkaran" is a semi-dugout based on a frame of whale bones or fin, covered with dry grass, skins, turf. Known as excellent hunters of whales, seals, sea lions, and walruses. In Russia, they reside in the Kamchatka Krai and on the Commander Islands. Which people are these?',
        quiz_question4_option1: 'EVENKS',
        quiz_question4_option2: 'ALEUTS',
        quiz_question4_option3: 'SAAMI',
        quiz_question4_option4: 'CHUKCHIS',

        //проблемы
        problems: "problems of indigenous peoples",
        russian_peoples: "peoples of Russia",
        economic_inequality: "economic inequality and demographic problems",
        climatic_threat: "climatic and environmental threats",
        climatic_threat_text: "Richer communities with more stable institutional structures suffer less from global issues such as warming and plastic pollution. However, the most vulnerable groups within these communities are often indigenous peoples who are committed to traditional ways of life and face difficulties due to changes in the environmental landscape. For example, permafrost melting in Northern Russia causes methane emissions and makes it impossible for indigenous peoples to engage in economic activities, hunting, and fishing.",
        language_threat: "threat to languages",
        language_threat_text: "The demographic problem has serious consequences for the cultures and languages of indigenous minorities. In Russia, 15 languages of small peoples have disappeared over the past 100 years, 10 of them in the last 35 years. Currently, about 10% of the one and a half hundred languages of the peoples of Russia are on the verge of extinction, and more than 60% are approaching this dangerous line. This problem is widespread not only in Russia but all over the world. Teaching indigenous minority languages in schools is also a challenging task, especially due to urbanization and the dominant role of the Russian language. Speaking one's language is an important part of a people's identity, so the extinction of languages leads to a loss of cultural values.",
        principle_free: "principle of free,",
        preliminary_and_conscious: "prior and informed",
        agreement: "consent (FPIC)",
        important_step: "An important step towards respecting the rights of indigenous peoples was the principle of free, prior and informed consent (FPIC). It was adopted as part of the United Nations Declaration on the Rights of Indigenous Peoples in 2007.",
        fpic_suppose: "The FPIC principle assumes that an organization or company must obtain the consent of the indigenous population before starting a project on their territory without administrative or political pressure. The residents must be informed about the possible consequences, and the consent procedure must comply with the norms of the indigenous people's decisions.",
        un_summary: "The UN report notes that one of the first Russian companies to cooperate with the indigenous population in implementing the Sakhalin-2 project in northern Sakhalin was Sakhalin Energy LLC. A Development Assistance Plan for the indigenous small-numbered peoples of northern Sakhalin was developed.",
        fpic_section_2: "The practice of FPIC principles in Russia remains irregular. The optimistic scenario of the Sakhalin plan can rather be considered an exception that confirms the general rule: even now, in the 21st century, after numerous UN reports and conferences, the rights of indigenous peoples in Russia are violated.",
        fpic_cases: "Here are just a few cases where the FPIC principles were not followed:",
        damn_gold: "Cursed Gold of the Shors.",
        damn_gold_section_1: "The Shors, an indigenous people of southern Siberia, live in the Republic of Khakassia and the Kemerovo region; their population is about 14,000 people, but since the mid-20th century, their number has decreased by 14% due to ecosystem destruction and gold mining. In the territory of seven regions of Russia, 279 licensed gold mining sites have been identified, posing a potential threat to the environment and humans.",
        damn_gold_section_2: "The industrial development of the ancestral territories of the Shors, including gold and coal mining, threatens the existence of this people, leading to the disappearance of their culture and language, forcing them to leave their lands and damaging the region's unique biodiversity. The principles of free, prior, and informed consent are not followed due to the informality of many mining sites and the lack of companies willing to take responsibility for the violations of Shors' rights and environmental pollution.",
        damn_gold_section_3: "Thus, adherence to the FPIC principle is an integral part of the rights of indigenous peoples worldwide. FPIC is necessary not only for preserving the unique cultures, languages, and methods of natural resource use of indigenous peoples but also as a practical step in establishing relationships with those groups that have been discriminated against for centuries.",
        dead_rivers: "Dead Rivers of the Mansi people.",
        dead_rivers_text: "Dead rivers such as Taltiya, Ivdel, Shegultan, and Olkhovka of the Mansi people in the Khanty-Mansi Autonomous Okrug and neighboring regions have become unsuitable for natural resource use due to industrial pollution, which hinders the traditional activities of the Mansi, such as fishing, hunting, and reindeer herding.",
        summary: "Conclusion",
        summary_text: "Despite the fact that some companies in Russia comply with FPIC requirements, the decision to adhere to or disregard this principle in most cases remains with the organizations themselves. Considering all the time and monetary costs, as well as the right of indigenous residents to refuse projects on their land, private companies rarely find sufficient grounds for negotiating with indigenous peoples. As a result, arbitrariness occurs on the historical territories of indigenous peoples, resource exploitation, the destruction of unique ecological landscapes, and violations of indigenous peoples' rights to self-determination.",
      
      //помощь
      
      title_help_page: 'help for indigenous peoples',
      link_len_obl: 'Indigenous Peoples Center of Leningrad Region',
      len_obl: ' a cultural and educational center created to preserve and develop the culture of the indigenous peoples of the Leningrad Region (Izhorians, Votes, Ingrian Finns, Vepsians, and Tikhvin Karelians).',
      link_peoples_center_pribaikal: 'Indigenous Peoples Culture Center of the Baikal Region',
      peoples_center_pribaikal: ' a center created to revive, preserve, and develop the traditional culture of the indigenous peoples of the Baikal region, engaging local residents, tourists, and visitors of the Irkutsk Region.',
      link_peoples_severa: 'Indigenous Minorities of the North',
      peoples_severa: ' a group dedicated to cultural events and activities of the Northern peoples.',
      forum_title: 'Forum of the Youth of Indigenous Minorities of the North, Siberia, and the Far East "Russian North"',
      forum_info: 'The forum is held with the aim of creating an interethnic communication platform for the interaction of youth and involving active young people in addressing tasks that meet the modern demands of the state and society.',
      link_history_peoples_dv: 'History of the Indigenous Peoples of the Far East',
      history_peoples_dv: 'A group dedicated to the history, religion, everyday life, and culture of the indigenous peoples of the Far East.',
      link_mammoth_effect: 'Mammoth Effect Foundation for Conservation and Support of Natural and Cultural Heritage',
      mammoth_effect: 'The foundation unites scientists, researchers, travelers, and representatives of the creative sphere to work towards preserving the unique heritage of our planet.',
      link_arctic_volunteers: 'Arctic Volunteers Project',
      arctic_volunteers: 'The project is aimed at assisting in the documentary support of residents of the Yamalo-Nenets Autonomous Okrug from among the indigenous minorities of the north as part of social programs provided by the legislation of the Yamalo-Nenets Autonomous Okrug for this category of citizens.',

      // природа

      title1_nature_page: 'interaction of the indigenous peoples of Russia with nature',
      introduction: 'Having studied academic works on comparative studies of indigenous peoples, we tried to reveal from different sides the experience of nature conservation of the indigenous inhabitants of Russia.',
      title2_nature_page: 'environmental justice emancipation of nature',
      environmental_justice: 'Environmental justice - recognition of the rights of non-human factors, in particular the natural world in all its diversity, as a full-fledged subject of political and economic relations.',
      principles_of_environmental_justice: "Individual principles of environmental protection\njustice has been done for a long time.\nNature reserves and conservation areas with\nspecial status, legislative\nbans on fishing at a certain time and in a certain territory,\nThe Red Book, the animals of which\n cannot be hunted, is nothing but\nindividual implementations of the principles of \nenvironmental justice.",
      recognition_of_the_rights_of_nature: 'Despite the above examples of legislative restrictions, the recognition of the rights of nature occurs only within those boundaries that affect human life. The researchers note that even the term “environment”, which we often use when we talk about protecting nature, implies only that part of nature that directly “surrounds” a person and in the preservation of which he is directly interested, since non-preservation leads to a threat to himself.',
      the_emancipation_of_nature: 'The natural world should become a full-fledged partner in relations with man, and cease to be the object of merciless exploitation. The emancipation of nature is the goal described by environmental justice.',
      title3_nature_page: 'principles of relations between indigenous peoples and nature',
      the_experience_of_indigenous_peoples: 'The experience of indigenous peoples living on the territory of Russia\nis the most important storehouse of relevant environmental practices\nthat can make our future more sustainable\nand nature more protected.',
      the_experience_of_indigenous_peoples_mobile: 'The experience of indigenous peoples living on the territory of Russia is the most important storehouse of relevant environmental practices that can make our future more sustainable and nature more protected.',
      catch_phrase1: 'Take only what you need, and only when you need it',
      the_text_of_the_catch_phrase1: 'It is impossible to imagine the inhabitants of any indigenous people destroying the forest that provides oxygen, or killing animals, solely because the price of fur coats has risen again.',
      catch_phrase2: 'If you take something from nature, use it to the end',
      the_text_of_the_catch_phrase2: 'The indigenous peoples of Russia mostly live in the North of Russia, where resources are limited. That is why, over the centuries, people in these harsh lands have learned to use all the available gifts of nature.',
      catch_phrase3: 'Nature is a sacred place',
      the_text_of_the_catch_phrase3: 'Indigenous peoples lives have been intertwined with wildlife for centuries, so natural practices have become inseparable from religious ones.',
      title4_nature_page: 'The wisdom of peoples - peace with the environment',
      centuries_old_connection: 'The centuries-old connection with the surrounding eco-landscape allows indigenous peoples to respond to environmental crises in a more balanced way.',
      resource_usage: 'When using water and land resources, indigenous peoples are more environmentally friendly than other communities. This is due to the fact that traditional farming methods of indigenous people are focused on non-commodity extraction of resources',
      the_worldview_of_peoples: 'The worldview of indigenous peoples is inextricably linked with the land of their ancestors, so they cannot allow their children to get a land affected by chemicals and poor in resources. Indigenous peoples have realized that it is necessary to plan the use of resources in the long term and help the environment recover if it has been affected by human activities.',
    
      // о нас

      title1_about_us_page: 'Our team',
      students: 'We, Arseny and Nastya, are students from St. Petersburg who are interested in the topic of ecology, environmental problems and ways to solve them.  Recently, we have learned a lot about the indigenous peoples of Russia and what kind of relationships they build with the outside world.',
      about_us1: '“Unfortunately, there is very',
      about_us2: 'little information about the',
      about_us3: 'indigenous peoples of our country, their way of',
      about_us4: 'life, problems and difficulties, and people',
      about_us5: 'also know very little about sufficiently',
      about_us6: 'environmentally friendly methods of',
      about_us7: 'using natural resources by indigenous peoples.',
      about_us8: 'Their love, care and awe towards',
      about_us9: 'nature can teach us all',
      about_us10: 'the right interaction with nature,',
      about_us11: 'which in the future will be an excellent step towards',
      about_us12: 'improving the environmental situation not',
      about_us13: 'only in Russia, but also around the world!”',
      about_us_mobile: '“Unfortunately, there is very little information about the indigenous peoples of our country, their way of life, problems and difficulties, and people also know very little about sufficiently environmentally friendly methods of using natural resources by indigenous peoples. Their love, care and awe towards nature can teach us all the right interaction with nature, which in the future will be an excellent step towards improving the environmental situation not only in Russia, but also around the world!”',
      team_russia_native: 'Team Russia Native',
      title2_about_us_page: 'interaction with nature',
      subtitle_title2_about_us: 'Equity and Partnership',
      nature_as_a_subject: 'We are moving away from the anthropocentric perception of ecology, where nature is important only in how it affects a person. Instead, being inspired by indigenous peoples, we see nature as a partner, not an object of exploitation. Our approach is based on the principles of environmental justice and partnership, where nature is a living entity with its own rights. We strive for harmony between man and nature, recognizing the importance of each for a sustainable future.',
      help_organization: 'how can you help our organization?',
      your_help: 'Where will my help go?',
      about_connection: 'How can I contact you?',
      gmail: 'Contact the foundation you’re interested in.',
      support: 'Your support helps preserve the culture and environment of indigenous communities across Russia.',
      contributions: 'Your contributions support cultural centers, conservation projects, and legal aid for indigenous communities.',

      // интервью

      title0_interviews_page: 'interview with Varvara Vasilyevna',
      about_the_employee: "Varvara Vasilina, an employee of the ethnographic sector of the N.I. Grodekov Khabarovsk Regional Museum. Varvara has been working in her position for a little more than one year (at the time of February 2024) and has been studying the indigenous population for the same time. Varvara's area of interest includes various aspects of indigenous peoples' lives, especially artistic crafts, intangible cultural heritage and shamanism.",
      title1_interviews_page: 'culture and lifestyle',
      types_of_activities1: 'Highlighting the cultural and economic features of the indigenous peoples, Varvara focused on their close connection with nature. The predominant activities – fishing and hunting – are conditioned by the natural conditions of the region. "Fish is the second bread" for the locals.',
      title2_interviews_page: 'harmony with nature',
      relationship_with_nature: 'The relationship with nature for indigenous peoples is based on harmony, not on the struggle for survival. Varvara emphasizes that over time, indigenous peoples have developed a system of appropriating farming that allows them to obtain resources without harming the environment.',
      title3_interviews_page: 'modern challenges',
      problems_of_peoples: 'Barbara has not spared the problems faced by indigenous peoples today. The destruction of traditional lands and lands for the development of natural resources, difficulties with fishing due to commercial activities and restrictions on hunting - all this threatens the sustainable development of indigenous peoples.',
      conclusion: 'conclusion',
      debriefing: 'Varvara stressed that despite the penetration of modern technologies, indigenous peoples are making significant efforts to preserve their traditions and way of life. This interview opens up an important perspective on the cultural and social significance of the indigenous peoples of Russia and highlights the need to support and preserve them.',
    
      // народы

      title1_nations_page: 'indigenous nations',
      nations_text1: 'Indigenous nations live on all continents, in countries with different political regimes, economic opportunities and social structures. Indigenous peoples have different histories, languages ​​and traditions.',
      nations_features: 'Indigenous nations have the following characteristics:',
      nations_feature1: 'Belonging to an indigenous nations as self-identification and, in some cases, recognition of their unique collective identity by other population groups or government bodies.',
      nations_feature2_1: 'Cultural identity is their voluntary choice and is passed down from generation to generation.',
      nations_feature2_2: 'It may include linguistic aspects, forms of social organization, religious and spiritual values, laws and institutions.',
      nations_feature3: 'The presence of ancient historical connections concerning the habitation of certain territories and the use of these territories.',
      nations_feature4: 'They have been and continue to be subject to oppression, marginalization, infringement of their legal rights, isolation or discrimination.',
      title2_nations_page: 'Indigenous Small Nations',
      nations_definition1: '  — special groups of the population living in the territories of traditional settlement of their ancestors, preserving the traditional way of life, economy and trades.',
      nations_criteria: 'In order for a nation to be recognized as indigenous and small in number, it must:',
      nations_criteria1: '1. To recognize oneself as an independent ethnic community',
      nations_criteria2: '2. Preserve the original habitat (territory), national crafts, original culture, and common native language.',
      nations_criteria3: '3. Have a population of less than 50k people on the territory of Russia',
      nations_criteria4: '4. There are currently 47 indigenous small nations in Russia - more than 300k people in total.',
      list_of_nations1: 'The indigenous small nations of Russia include:',
      small_nations: 'Abazines,Aleuts,Alutorians,Besermians,Veps,Vod,Dolgans,Izhora,Itelmen,Kamchadals,Kerek,Kets,Koryaks,Kumandins,Mansi,Nagaibaks,Nanais,Nganasans,Negidals,Nenets,Nivkhs,Oroks,Orochi,Khanty,Chelkans,Chuvans,Chukchi,Chulyms,Shapsugs,Shorts,Evenks,Evens,Ents,Eskimos,Yukaghirs,Sami,Selkups,Setu,Soyots,Taz,Telengits,Teleouts,Tofalars,Tubalars,Tuvans-Todzhins,Udege,Ulchi',
      smallest_nations: 'Of these, the smallest are: Kereks (4 people) Here (59 people) Set (214 people) Ents (227 people) Izhora (266 people)',
      title3_nations_page: 'LARGE INDIGENOUS NATIONS',
      nations_definition2: 'Large indigenous nations are indigenous nations on the territory of Russia who are not on the official list of small indigenous nations of Russia.',
      list_of_nations2: 'The major indigenous nations of Russia include:',
      large_nations: 'Buryats,Chukchi,Yakuts,Balkars,Chechens,Circassians,Ingush,Karachai,Ossetians,Karelians,Komi,Nenets,Altai,Khakas,Tuvinians,Aguls,Avars,Chechens-Aukhov,Azerbaijanis in Dagestan,Dargins,Kumyks,Laks,Lezgins,Nogais,Ethnic Russians,Rutulians,Tabasarans,Tats,Tsakhurs,Crimean Tatars,Kalmyks,Khanty,Mansi,Bashkirs,Chuvash,Mari,Mordvins,Tatars,Udmurts',
      types_of_activities2: 'Types of traditional economic activities of the indigenous nations of Russia',
      nations_text2: 'Indigenous nations live throughout Russia, they can be found in the Caucasus, the Far East, Siberia and the Urals. Different locations, climatic zones, and the availability of certain resources have determined the activities of the indigenous inhabitants of different territories.\n\nThe main economic activities of the indigenous people are the following occupations, but each nation has its own, as it depends on climatic conditions and the availability of resources.',
      activity_card1: `Animal husbandry, including nomadic processing. \n\nLivestock products.\n\nDog breeding \n\nAnimal breeding, processing and sale of animal husbandry products.`,
      activity_card2: `Beekeeping, beekeeping.\n\n Fishing and the sale of aquatic biological resources.\n\n Commercial hunting, processing and sale of hunting products.`,
      activity_card3: `Agriculture, cultivation and processing of medicinal plants.\n\nHarvesting of wood and non-wood forest resources for their own needs.\n\ncollecting`,
      activity_card4: `Extraction and processing of common minerals for their own needs.\n\nArts and crafts and folk crafts. \n\n Construction of national traditional dwellings and other buildings necessary for the implementation of traditional economic activities.`,
    }

  },
  ru: {
    translation: {
        //section1
        about_us: "О нас",
        we_care_about: "Мы волнуемся за экологические изменения, происходящие на нашей планете:",
        climate_change: "изменение климата, уменьшение биоразнообразия, угроза уникальным растениям",
        and_animals: "и животным",
        recently_learned: "Недавно мы узнали много нового о коренных народах России и о том, какие",
        relationships_with_nature: "отношения с окружающим миром они выстраивают",

        //section2
        our_mission: "Наша миссия",
        mission_statement_1: "Миссия данного проекта - обратить внимание общественности на коренные народы",
        mission_statement_2: "России, их экологичный образ жизни и практики по сохранению окружающей среды с",
        mission_statement_3: "целью перенятия их способов взаимодействия с природой для улучшения",
        mission_statement_4: "экологического состояния нашей планеты.",

        //section3
        indigenous_small_numbered_people_definition: "Согласно российскому законодательству, коренной малочисленный народ — это тот, который расселен на территории расселения своих предков, наследует традиционные методы хозяйствования и чья численность не превышает 50 000 человек.",
        learn_more: "Узнать больше",
        game_description: "Во время игры в хээр шааглан участник должен разбить ладонью хребтовую кость быка или коровы. Чтобы суметь сломать кость, используется специальная техника: делается несколько пробных ударов, чтобы «почувствовать» кость, и потом тыльной стороной ладони участник или участница наносит финальный самый сильный удар по кости. Эта игра — часть традиционной культуры бурятов.",
        game_description_mobile: "Во время игры в хээр шааглан участник должен разбить ладонью хребтовую кость быка или коровы. Чтобы суметь сломать кость, используется специальная техника. Эта игра — часть традиционной культуры бурятов.",
        indigenous_peoples_challenges: "Сложность сохранения языка, исчезновение древних традиций, угроза национальным культурам, разрушение экосистем — с этими проблемами сталкиваются коренные народы не только России, но и других стран. В 1992 году в ООН была принята «Декларация о правах лиц, принадлежащих к национальным или этническим, религиозным и языковым меньшинствам», закреплявшая права меньшинств в многосоставных государствах.",
        indigenous_peoples_challenges_mobile: "В 1992 году в ООН была принята «Декларация о правах лиц, принадлежащих к национальным или этническим, религиозным и языковым меньшинствам», закреплявшая права меньшинств в многосоставных государствах.",
        // indigenous_teaching_quote1: "Коренные народы России учат нас жить в гармонии с природой,",
        // indigenous_teaching_quote2: "демонстрируя уважение к земле и устойчивость для будущих",
        // indigenous_teaching_quote3: "поколений.",
        indigenous_teaching_quote0: "Коренные народы России учат нас жить в гармонии с природой, демонстрируя уважение к земле и устойчивость для будущих поколений.",
        author_arseny_karsakov: "Карсаков Арсений",
        our_policy_title: "Наша политика",
        culture_preservation_title: "Сохранение культуры",
        culture_preservation_description: "Мы стремимся повышать осведомленность о коренных народах России и их экологических практиках, уважая их традиции и права. Наша задача — распространять знания об устойчивом взаимодействии с природой, опираясь на голоса и перспективы этих народов.",
        educational_interaction_title: "Образовательное взаимодействие",
        educational_interaction_description: "Через интерактивную викторину мы стимулируем изучение экологических практик коренных народов, способствуя глубокому пониманию и уважению к их культурам. Образование считаем ключом к экологической устойчивости.",
        website_information_title: "Информация на сайте",
        website_information_description: "Созданный с целью освещения жизни коренных народов, наш сайт обязуется предоставлять актуальную и уважительную информацию, отражающую последние исследования. Мы стремимся к улучшению экологической ситуации через расширение знаний о коренных культурах и практиках.",

        //section4
        section4_quote: "Природа может обойтись без человека, а он без нее - нет.",
        section4_author: "Али Апшерон",
        section4_quote_mobile_1: "Природа может обойтись без человека,",
        section4_quote_mobile_2: "а он без нее - нет.",

        //section5
        help_title: "Вы можете помочь",
        help_message: "Защитите людей и места, которые вы любите. Сделайте ваш вклад сегодня.",
        help_button: "да - я хочу помочь!",
        help_button_mobile: "Хочу помочь!",

        //footer
        footer_write_to_us: "Напишите нам",
        footer_email: "russianative@gmail.com",
        footer_description: "Мы создали полезный сайт, в котором собрали информацию о коренных жителях нашей страны, их проблемах, а также способах взаимодействия с окружающей средой, которые не только эффективны, но и безопасны для природы.",
        footer_copyright: "© 2024 Коренные Народы, Сайт от safroalex Team",
        footer_nations_nature: "народы и природа",
        footer_learn_more: "узнать больше",
        footer_nations_interview: "народы и интервью",

        //sliderAndQuiz
        check_your_knowledge: 'проверьте свои знания',
        correct_answers_count: 'Количество правильных ответов',
        correct_answers_count_from: 'из',
        test_completed: 'Вы завершили тест',

        fact_correct_answer: 'Да, это правильный ответ',
        fact_wrong_answer: 'Нет, это неправильный ответ',
        fact_indigenous_peoples_count: 'Согласно "Единому перечню коренных малочисленных народов Российской Федерации" в России проживает 47 коренных малочисленных народов',
        fact_kereks_population: 'Кереки. Во время Всероссийской переписи населения в 2021 году в кереки себя записало всего 4 человека. Это правильный ответ.',

        quiz_question1: 'Вопрос 1',
        quiz_question1_text: 'А вы знаете, сколько коренных малочисленных народов проживает в России?',
        quiz_question1_option1: 'БОЛЬШЕ 50',
        quiz_question1_option2: '193',
        quiz_question1_option3: '47',
        quiz_question1_option4: '29',

        quiz_question2: 'Вопрос 2',
        quiz_question2_text: 'В каком народе, согласно последним исследованиям, насчитывается всего 4 человека?',
        quiz_question2_option1: 'КЕРЕКИ',
        quiz_question2_option2: 'ЮКАГИРЫ',
        quiz_question2_option3: 'ЭНЦЫ',
        quiz_question2_option4: 'ОРОЧИ',
        
        quiz_question3: 'Вопрос 3',
        quiz_question3_text: 'Варган, комус, хомус, комыс, аманхуур, тумран, зубанка - все эти названия обозначают один важный предмет для культурной жизни народов севера. А вы поняли, что это за предмет?',
        quiz_question3_option1: 'МУЗЫКАЛЬНЫЙ ИНСТРУМЕНТ',
        quiz_question3_option2: 'ЛЮЛЬКА ДЛЯ РЕБЕНКА',
        quiz_question3_option3: 'КУРИТЕЛЬНАЯ ТРУБКА',
        quiz_question3_option4: 'СПЕЦИАЛЬНЫЙ ОБРЯДОВЫЙ НОЖ',

        quiz_question4: 'Вопрос 4',
        quiz_question4_text: 'Представители этого народа живут на два континента - Евразию и Северную Америку. Традиционное жилище “валкаран” представляет собой полуземлянку на основе каркаса из костей кита или плавника, укрытую сухой травой, шкурами, дерном. Известны как отличные охотники на китов, морских котиков, каланов и сивучей. В России проживают на территории Камчатского края и на Командорских островах. Что это за народ?',
        quiz_question4_option1: 'ЭВЕНКИ',
        quiz_question4_option2: 'АЛЕУТЫ',
        quiz_question4_option3: 'СААМИ',
        quiz_question4_option4: 'ЧУКЧИ',

        //problems
        problems: 'проблемы коренных',
        russian_peoples: 'народов россии',
        economic_inequality: 'экономическое неравенство и демографические проблемы',
        climatic_threat: 'КЛИМАТИЧЕСКАЯ И ЭКОЛОГИЧЕСКАЯ УГРОЗЫ',
        climatic_threat_text: 'Более богатые сообщества с более стабильными институциональными структурами меньше страдают от глобальных проблем, таких как потепление и загрязнение пластиком. Однако, наиболее уязвимыми группами внутри этих сообществ часто оказываются коренные жители, которые привержены традиционным способам жизни и испытывают сложности из-за изменений в экологическом ландшафте. Например, таяние вечной мерзлоты в Северной России вызывает выбросы метана и делает невозможным хозяйственную деятельность, охоту и рыболовство для коренных народов.',
        language_threat: 'УГРОЗА ЯЗЫКАМ ',
        language_threat_text: 'Демографическая проблема имеет серьезные последствия для культур и языков коренных меньшинств. В России за последние 100 лет исчезли 15 языков малочисленных народов, 10 из которых - за последние 35 лет. В настоящее время около 10% из полутора сотен языков народов России находятся на грани исчезновения, а более 60% приближаются к этой опасной черте. Эта проблема распространена не только в России, но и по всему миру. Преподавание языков коренных меньшинств в школах также является сложной задачей, особенно из-за урбанизации и доминирующей роли русского языка. Разговаривать на своем языке - важная часть идентичности народа, поэтому вымирание языков влечет за собой потерю культурных ценностей.',
        principle_free: 'ПРИНЦИП СВОБОДНОГО,',
        preliminary_and_conscious: 'ПРЕДВАРИТЕЛЬНОГО И ОСОЗНАНОГО',
        agreement: 'СОГЛАСИЯ (СПОС)',
        important_step: 'Важным шагом на пути к соблюдению прав коренных народов стал принцип свободного, предварительного согласия (СПОС).  Он был принят в рамках Декларации Объединенных Наций о правах коренных народов в 2007 году.',
        fpic_suppose: 'Принцип СПОС предполагает, что организация или компания должна получить согласие коренного населения перед началом проекта на их территории без административного или политического давления. Жители должны быть информированы о возможных последствиях, а процедура согласования должна соответствовать нормам решений коренного народа.',
        un_summary: 'В докладе ООН отмечается, что одной из первых российских компаний, сотрудничавших с коренным населением при реализации проекта "Сахалин-2" на севере Сахалина, стала ООО "Сахалинская Энергия". Был разработан План содействия по развитию коренных малочисленных народов севера Сахалина.',
        fpic_section_2: 'Практика принципов СПОС в России остается нерегулярной. Оптимистичный сценарий сахалинского плана можно скорее считать исключением, подтверждающим общее правило: даже сейчас, в 21 веке, после многочисленных докладов и конференций ООН, права коренных народов России нарушаются.',
        fpic_cases: 'Вот лишь несколько кейсов, когда принципы СПОС не были соблюдены:',
        damn_gold: 'Проклятое золото Шорцев.',
        damn_gold_section_1: 'Шорцы, коренной народ Южной Сибири, проживают в Республике Хакасия и Кемеровской области; их численность составляет около 14 тысяч человек, но с середины 20 века их численность сократилась на 14% из-за разрушения экосистемы и золотодобычи. На территории семи регионов России было выявлено 279 лицензионных участков по добыче золота,  представляющих потенциальную угрозу окружающей среде и человеку.',
        damn_gold_section_2: 'Промышленное освоение исконных территорий шорцев, включая добычу золота и угля, угрожает существованию этого народа, приводя к исчезновению их культуры и языка, вынуждая их покидать свои земли и повреждая уникальное биоразнообразие региона, при этом принципы свободного, предварительного и осознанного согласия не соблюдаются из-за неофициальности многих мест разработки и отсутствия компаний, готовых нести ответственность за нарушения прав шорцев и загрязнение окружающей среды.',
        damn_gold_section_3: 'Таким образом, соблюдение принципа СПОС - неотъемлемая часть прав коренного населения по всему миру. СПОС не только необходимо для сохранения уникальных культур, языков и методов природопользования коренных народов, но и практический шаг в налаживании отношений с теми группами,  которые дискриминировались веками.',
        dead_rivers: 'Мертвые реки надода Манси.',
        dead_rivers_text: 'Мертвые реки такие как (Тальтия, Ивделя, Шегультан и Ольховка) народа манси в Ханты-Мансийском автономном округе и соседних регионах стали непригодны для природопользования из-за загрязнения промышленными выбросами, что препятствует традиционным видам занятий манси, таким как рыболовство, охота и оленеводство.',
        summary: 'Вывод',
        summary_text: 'Несмотря на то, что некоторые компании в России соблюдают требования СПОС, решение о выполнении или невыполнении этого принципа в большинстве случаев остается за самими организациями. При этом, учитывая все временные и денежные затраты, а также право коренных жителей отказаться от проектов на своей земле, частные компании редко находят достаточные основания для ведения переговоров с коренными народами. В результате этого происходит произвол на исторических территориях коренного населения, эксплуатация ресурсов, разрушение уникальных экологических ландшафтов и нарушение прав коренных народов на самоопределение.',

        //help
        title_help_page: 'помощь коренным народам',
        link_len_obl: 'Центр коренных народов Ленинградской области',
        len_obl: ' культурно-просветительский центр, созданный с целью сохранения и развития культуры коренных народов Ленинградской области (ижоры, води, ингерманландских финнов, вепсов и тихвинских карел).',
        link_peoples_center_pribaikal: 'Центр культуры коренных народов Прибайкалья',
        peoples_center_pribaikal: ' центр, созданный с целью возрождения, сохранения и развития традиционной культуры коренных народов Прибайкалья, приобщение к ней жителей, туристов и гостей Иркутской области.',
        link_peoples_severa: 'Коренные малочисленные народы Севера',
        peoples_severa: ' группа посвящена культурным мероприятиям и событиям народов Севера.',
        forum_title: 'Форум молодежи коренных малочисленных народов Севера, Сибири и Дальнего Востока «Российский Север»',
        forum_info: 'Форум проводится с целью создания межнациональной коммуникационной площадки для взаимодействия молодежи и вовлечения активных молодых людей в решение задач, отвечающих современным запросам государства и общества.',
        link_history_peoples_dv: 'История коренных народов Дальнего Востока',
        history_peoples_dv: 'Группа посвящена истории, религия, быту, культуре коренных народов Дальнего Востока.',
        link_mammoth_effect: 'Фонд сохранения и поддержки природного и культурного наследия «Эффект Мамонта»',
        mammoth_effect: 'Фонд объединяет ученых, исследователей, путешественников и представителей творческой сферы для того, чтобы трудиться над сохранением уникального наследия нашей планеты.',
        link_arctic_volunteers: 'Волонтерский проект "Добровольцы Арктики"',
        arctic_volunteers: 'Проект направлен на оказание помощи в документальном сопровождения жителей ЯНАО из числа КМНС (коренных малочисленных народов севера) в рамках социальных программ предусмотренных Законодательством ЯНАО для данной категории граждан.',

        // nature

        title1_nature_page: 'взаимодействие коренных народов России с природой',
        introduction: 'Изучив академические работы по сравнительным  исследованиям коренных народов, мы постарались с разных сторон раскрыть опыт сохранения природы  коренных жителей России.',
        title2_nature_page: 'экологическая справедливость эмансипация природы',
        environmental_justice: 'Экологическая справедливость - признание прав\nнечеловеческих акторов, в частности природного мира\nво всем его многообразии, как полноправного субъекта\nполитических и экономических отношений.',
        principles_of_environmental_justice: "Отдельные принципы экологической\nсправедливости уже давно выполняются.\nЗаповедники и природоохранные зоны с\nособым статусом, законодательные\nзапреты на вылов рыбы в определенное\nвремя и на определенной территории,\nКрасная книга, на животных которой\nнельзя охотиться, - все это не что иное, как\nотдельные имплементации принципов\nэкологической справедливости.",
        recognition_of_the_rights_of_nature: 'Несмотря на вышеперечисленные примеры законодательных ограничений, признание прав природы происходит лишь в тех границах, которые затрагивают жизнедеятельность человека. Исследователи отмечают, что даже сам термин “окружающая среда”, который мы часто используем, когда говорим о защите природы, подразумевает лишь ту часть природы, которая непосредственно “окружает” человека и в сохранении которой он непосредственно заинтересован, так как несохранение - ведет к угрозе ему самому.',
        the_emancipation_of_nature: 'Природный мир должен стать полноправным партнером в отношениях с человеком, а перестать быть объектом нещадной эксплуатации. Эмансипация природы - вот цель, которая описывается экологической справедливостью.',
        title3_nature_page: 'принципы отношений между коренными народами и природой',
        the_experience_of_indigenous_peoples: 'Опыт коренных народов, проживающих на территории России,\n- важнейшая кладезь актуальных экологических практик,\nспособных сделать наше будущее более устойчивым, а\nприроду более защищенной.',
        the_experience_of_indigenous_peoples_mobile: 'Опыт коренных народов, проживающих на территории России, - важнейшая кладезь актуальных экологических практик, способных сделать наше будущее более устойчивым, а природу более защищенной.',
        catch_phrase1: 'Брать только то, что нужно, и только, когда нужно',
        the_text_of_the_catch_phrase1: 'Невозможно представить жителей любого коренного народа, уничтожающих лес, который дает кислород, или убивающих животных, исключительно потому, что опять поднялась цена на шубы.',
        catch_phrase2: 'Если что-то берешь у природы - используй до конца',
        the_text_of_the_catch_phrase2: 'Коренные народы России в большинстве своем проживают на Севере России, где ресурсы ограничены. Именно поэтому за века люди в этих суровых краях научились использовать все доступные дары природы.',
        catch_phrase3: 'Природа - священное место',
        the_text_of_the_catch_phrase3: 'Жизнь коренных народов была веками переплетена с дикой природой, поэтому природные практики стали неотделимы от религиозных.',
        title4_nature_page: 'мудрость народов - мир с окружающей средой',
        centuries_old_connection: 'Многовековая связь с окружающим эко-ландшафтом позволяют коренным народам более сбалансированно реагировать на экологические кризисы.',
        resource_usage: 'При использовании водных и земельных ресурсов коренные народы экологичнее, чем другие сообщества. Это связано с тем, что традиционные методы хозяйствования коренных жителей ориентированы на нетоварную добычу ресурсов',
        the_worldview_of_peoples: 'Мировоззрение коренных народов неразрывно связано с землей предков, поэтому они не могут допустить, чтобы их детям земля досталась пораженная химикатами и бедная ресурсами. Коренные народы осознали, что необходимо планировать использование ресурсов в долгосрочной перспективе и помогать экологии восстанавливаться, если она пострадала от деятельности человека.',

        // about us

        title1_about_us_page: 'Наша команда',
        students: 'Мы, Арсений и Настя, студенты из Санкт-Петербурга, которые заинтересованы в теме экологии, экологических проблем и путей их решения.  Недавно мы узнали много нового о коренных народах России и о том, какие отношения с окружающим миром они выстраивают.',
        about_us1: '“К сожалению, существует совсем',
        about_us2: 'небольшое количество информации о',
        about_us3: 'коренных народах нашей страны, их образе',
        about_us4: 'жизни, проблемах и трудностях, люди',
        about_us5: 'также знают очень мало о достаточно',
        about_us6: 'экологичных методах использования',
        about_us7: 'природных ресурсов коренными народами.',
        about_us8: 'Их любовь, забота и трепет по отношению',
        about_us9: 'к природе может научить всех нас',
        about_us10: 'правильному взаимодействию с природой,',
        about_us11: 'что в будущем станет отличным шагом к',
        about_us12: 'улучшению экологической ситуации не',
        about_us13: 'только в России, но и во всём мире!”',
        about_us_mobile: '“К сожалению, существует совсем небольшое количество информации о коренных народах нашей страны, их образе жизни, проблемах и трудностях, люди также знают очень мало о достаточно экологичных методах использования природных ресурсов коренными народами. Их любовь, забота и трепет по отношению к природе может научить всех нас правильному взаимодействию с природой, что в будущем станет отличным шагом к улучшению экологической ситуации не только в России, но и во всём мире!”',
        team_russia_native: 'Команда Russia Native',
        title2_about_us_page: 'взаимодействие с природой',
        subtitle_title2_about_us: 'Справедливость и Партнерство',
        nature_as_a_subject: 'Мы отходим от антропоцентричного восприятия экологии, где природа важна только тем, как она влияет на человека. Вместо этого, вдохновляясь коренными народами, мы видим в природе партнера, а не объект эксплуатации. Наш подход основан на принципах экологической справедливости и партнерства, где природа — это живой субъект с собственными правами. Мы стремимся к гармонии между человеком и природой, признавая важность каждого для устойчивого будущего.',
        help_organization: 'как можно помочь нашей организации?',
        your_help: 'куда будет уходить моя помощь?',
        about_connection: 'как я могу с вами связаться?',
        gmail: 'Обратитесь в фонд, который вас интересует.',
        contributions: 'Ваша поддержка помогает сохранить культуру и окружающую среду коренных народов России.',
        support: 'Ваш вклад поддержит культурные центры, проекты по сохранению природы и юридическую помощь для коренных сообществ.',

        // interviews

        title0_interviews_page: 'интервью с варварой василиной',
        about_the_employee: 'Варвара Василина, сотрудница этнографического сектора Хабаровского краевого музея им. Н.И. Гродекова. Варвара работает в своей должности чуть больше одного года (на момент февраля 2024 года) и на протяжении этого же времени изучает коренное население. В сфера интересов Варвары входят различные аспекты жизни коренных народов в особенности художественные промыслы, нематериальное культурное наследие и шаманизм.',
        title1_interviews_page: 'культура и образ жизни',
        types_of_activities1: 'Освещая культурные и хозяйственные особенности коренных народов, Варвара акцентировала внимание на их тесной связи с природой. Преобладающие виды деятельности – рыболовство и охота – обусловлены естественными условиями региона. "Рыба - второй хлеб" для местных жителей.',
        title2_interviews_page: 'гармония с природой',
        relationship_with_nature: 'Взаимоотношения с природой для коренных народов основаны на гармонии, а не на борьбе за выживание. Варвара подчеркивает, что со временем коренные народы разработали систему присваивающего хозяйства, позволяющую получать ресурсы без ущерба для окружающей среды.',
        title3_interviews_page: 'современные вызовы',
        problems_of_peoples: 'Не обошла стороной Варвара и проблемы, с которыми сталкиваются коренные народы сегодня. Разрушение традиционных угодий и земель для освоения природных ресурсов, сложности с занятием рыболовством из-за коммерческой деятельности и ограничения на охотничий промысел – все это угрожает устойчивому развитию коренных народностей.',
        conclusion: 'заключение',
        debriefing: 'Варвара подчеркнула, что, несмотря на проникновение современных технологий, коренные народы прилагают значительные усилия для сохранения своих традиций и образа жизни. Это интервью открывает важный взгляд на культурное и социальное значение коренных народов России и подчеркивает необходимость их поддержки и сохранения.',
      
        //nations

        title1_nations_page: 'КОРЕНЫЕ НАРОДЫ',
        nations_text1: 'Представители коренного населения живут на всех континентах, в странах с разным политическим режимом, экономическими возможностями и социальным устройством. У коренных народов разная история, разные языки и традиции.',
        nations_features: 'коренные народы обладают следующими характеристиками:',
        nations_feature1: 'Принадлежность к коренному народу как самоидентификация, а в некоторых случаях – признание их уникальной коллективной самобытности со стороны других групп населения или государственных органов.',
        nations_feature2_1: 'Культурная самобытность является их добровольным выбором и передается из поколения в поколение.',
        nations_feature2_2: 'Она может включать языковые аспекты, формы социальной организации, религиозные и духовные ценности, законы и институты.',
        nations_feature3: 'Наличие древних исторических связей, касающихся проживания на определенных территориях и использования этих территорий.',
        nations_feature4: 'Они подвергались и подвергаются угнетению, маргинализации, ущемлению их законных прав, изоляции или дискриминации.',
        title2_nations_page: 'Коренные малочисленные народы',
        nations_definition1: '  — осо­бые груп­пы на­се­ле­ния, про­жи­ваю­щие на тер­ри­то­ри­ях тра­ди­ци­он­но­го рас­се­ле­ния их пред­ков, со­хра­няю­щие тра­ди­ци­он­ный об­раз жиз­ни, хо­зяй­ст­во­ва­ние и про­мыс­лы.',
        nations_criteria: 'Для признания народа коренным малочисленным он должен:',
        nations_criteria1: '1. Осознавать себя самостоятельной этнической общностью',
        nations_criteria2: '2. Сохранять исконную среду обитания (территорию), национальные промыслы, самобытную культуру, общий родной язык.',
        nations_criteria3: '3. Иметь численность на территории России менее 50 тыс. человек',
        nations_criteria4: '4. В России в настоящее время насчитывается 47 коренных малочисленных народов - всего более 300 тыс. человек.',
        list_of_nations1: 'К коренным малочисленным народам России относятся:',
        small_nations: 'Абазины,Алеуты,Алюторцы,Бесермяне,Вепсы,Водь,Долганы,Ижоры,Ительмены,Камчадалы,Кереки,Кеты,Коряки,Кумандинцы,Манси,Нагайбаки,Нанайцы,Нганасаны,Негидальцы,Ненцы,Нивхи,Ороки,Орочи,Ханты,Челканцы,Чуванцы,Чукчи,Чулымцы,Шапсуги,Шорцы,Эвенки,Эвены,Энцы,Эскимосы,Юкагиры,Саамы,Селькупы,Сету,Сойоты,Тазы,Теленгиты,Телеуты,Тофалары,Тубалары,Тувинцы-тоджинцы,Удэгейцы,Ульчи',
        smallest_nations: 'Из них самыми малочисленными являются: Кереки (4 человека) Водь (59 человек) Сету (214 человек) Энцы (227 человек) Ижоры (266 человек)',
        title3_nations_page: 'КРУПНЫЕ КОРЕННЫЕ НАРОДЫ',
        nations_definition2: 'Крупные коренные народы - коренные народы на территории России, которых нет в официальном списке коренных малочисленных народов России.',
        list_of_nations2: 'К крупным коренным народам России относятся:',
        large_nations: 'Буряты,Чукчи,Якуты,Балкарцы,Чеченцы,Черкесы,Ингуши,Карачаевцы,Осетины,Карелы,Коми,Ненцы,Алтай,Хакасы,Тувинцы,Агулы,Аварцы,Чеченцы-ауховцы,Азербайджанцы в Дагестане,Даргинцы,Кумыки,Лакцы,Лезгины,Ногайцы,Этнические русские,Рутульцы,Табасаранцы,Таты,Цахуры,Крымские татары,Калмыки,Ханты,Манси,Башкиры,Чуваши,Марийцы,Мордва,Татары,Удмурты',
        types_of_activities2: 'Виды традиционной хозяйственной деятельности коренных народов Росии',
        nations_text2: 'Коренные народы живут на всей территории России, их можно встретить на Кавказе, Дальнем Востоке, в Сибири и на Урале. Разное местоположение, климатическое зоны, доступность тех или иных ресурсов определили деятельность коренных жителей разных территорий.\n\nОсновными видами хозяйственной деятельности коренных жителей являются нижеприведенные занятия, однако у каждого народа они свои, так как это зависит от климатических условий и доступности ресурсов.',
        activity_card1: `Животноводство, в том числе кочевая переработка. \n\nПродукции животноводства.\n\nСобаководство. \n\nРазведение зверей, переработка и реализация продукции звероводства.`,
        activity_card2: `Бортничество, пчеловодство.\n\nРыболовство и реализация водных биологических ресурсов.\n\nПромысловая охота, переработка и реализация охотничьей продукции.`,
        activity_card3: `Земледелие, разведение и переработка ценных в лекарственном отношении растений.\n\nЗаготовка древесины и недревесных лесных ресурсов для собственных нужд.\n\nСобирательство.`,
        activity_card4: `Добыча и переработка общераспространенных полезных ископаемых для собственных нужд.\n\nХудожественные промыслы и народные ремесла. \n\nСтроительство национальных традиционных жилищ и других построек, необходимых для осуществления традиционных видов хозяйственной деятельности.`,
      }

  }
};

const currentLanguage = localStorage.getItem('language') || 'ru'; // язык по умолчанию, если в localStorage нет сохраненного значения

i18n
  .use(initReactI18next) // пропускает i18n вниз до react-i18next
  .init({
    resources,
    lng: currentLanguage, // Установка языка на основе сохраненного значения или использование 'ru' по умолчанию
    keySeparator: false, // мы не используем или не нуждаемся в ключах в форме messages.welcome
    interpolation: {
      escapeValue: false // react уже защищает от инъекций
    }
  });

export default i18n;
