import React from 'react';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import line from './../../images/line.jpg';
import landscape1 from './../../images/landscape1-nature.jpg';
import landscape1_mobile from './../../images/landscape1-nature-mobile.jpg';
import landscape2 from './../../images/landscape2-nature.jpg';
import landscape3 from './../../images/landscape3-nature.jpg';
import pine_trees from './../../images/pine-trees.jpg';
import deer from './../../images/deer.jpg';
import exchange from './../../images/exchange.jpg';
import ritual from './../../images/ritual.jpg';
import child from './../../images/yakut-two-wolves.jpg';
import './Nature.css';
import {useTranslation} from "react-i18next";

function Nature() {
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <div className='nature-sec1'>
        <div className='nature-sec1-inner'>
          <div className='title-nature'>
            <p>{t('title1_nature_page')}</p>
          </div>
          <div className='line-nature'>
            <img src={line} alt="" />
          </div>
          <div>
            <img src={landscape1} alt="" />
            <img src={landscape1_mobile} alt="" />
          </div>
          <div className='text-nature'>
            <p>{t('introduction')}</p>
          </div>
          <div className="line-nature">
            <img src={line} alt="" />
          </div>
        </div>
      </div>
      <div className='nature-sec2'>
        <div className="nature-sec2-inner">
          <div className='text-main-nature'>
            <div className='title-nature'>
              <p>{t('title2_nature_page')}</p>
            </div>
            <div className='unifying-nature'>
              <div className='text-nature-outer'>
                <div className="text-nature">
                  <p>{t('environmental_justice')}</p>
                </div>
                <div className="text-nature">
                  <p>{t('principles_of_environmental_justice')}</p>
                </div>
              </div>
              <div className='box-hide'>
                <img srcSet={`${landscape2} 1920w`}/>
                <img srcSet={`${landscape3} 1920w`}/>
              </div>
            </div>
          </div>
          <div className='text-nature sec2-p'>
            <p>{t('recognition_of_the_rights_of_nature')}</p>
          </div>
          <div className='text-nature sec2-p'>
            <p>{t('the_emancipation_of_nature')}</p>
          </div>
          <div className='img-mobile'>
            <img srcSet={`${landscape2} 480w`}/>
            <img srcSet={`${landscape3} 480w`}/>
          </div>
          <div className="line-nature">
            <img src={line} alt="" />
          </div>
        </div>
      </div>
      <div className='nature-sec3'>
        <div className="nature-sec3-inner">
          <div className='title-nature'>
            <p>{t('title3_nature_page')}</p>
          </div>
          <div className="text-nature text1-sec3And4-nature">
            <p className='text-hide'>{t('the_experience_of_indigenous_peoples')}</p>
            <p className='text-mobile'>{t('the_experience_of_indigenous_peoples_mobile')}</p>
          </div>
          <div className='img-mobile'>
            <img srcSet={`${exchange} 480w`}/>
            <img srcSet={`${child} 480w`}/>
          </div>
          <div className="text-nature text2-sec3-nature">
            <div>
              <p>{t('catch_phrase1')}</p>
            </div>
            <p>{t('the_text_of_the_catch_phrase1')}</p>
          </div>

          <div className='absolute-wrapper'>
            <div className='box-hide'>
              <img srcSet={`${exchange} 1920w`} />
              <img srcSet={`${child} 1920w`} />
            </div>
          </div>
          


          <div className='flex-bottom-text-sec3-nature'>
            <div className='img3-4-sec3-nature box-hide'>
              <img srcSet={`${deer} 1920w`} />
              <img srcSet={`${pine_trees} 1920w`} />
            </div>
            <div className='p-sec3-nature'>
              <div>
                <div className="text-nature">
                  <p>{t('catch_phrase2')}</p>
                </div>
                <p>{t('the_text_of_the_catch_phrase2')}</p>
              </div>
              <div className="text-nature">
                <div>
                  <p>{t('catch_phrase3')}</p>
                </div>
                <p>{t('the_text_of_the_catch_phrase3')}</p>
              </div>
            </div>  
          </div>

          <div className='img3-4-sec3-nature-mobile'> 
            <img srcSet={`${deer} 480w`}/>
            <img srcSet={`${pine_trees} 480w`}/>
          </div>


          <div className="line-nature">
            <img src={line} alt="" />
          </div>


        </div>
      </div>
      <div className="nature-sec4">
        <div className="nature-sec4-inner">
          <div className='title-nature'>
            <p>{t('title4_nature_page')}</p>
          </div>
          <div className='flex-sec4-nature'>
            
            <div className="text1-sec3And4-nature-mobile">
              <p>{t('centuries_old_connection')}</p>
            </div>

            <div className='img-mobile'>
              <img srcSet={`${ritual} 480w`}/>
            </div>
            <div className='box-hide img1-nature'>
              <img srcSet={`${ritual} 1920w`} />
            </div>
            <div className='common-p-sec4-nature'>
              <div className="text-nature text1-sec3And4-nature block-hide">
                <p>{t('centuries_old_connection')}</p>
              </div>
              <div className="text-nature">
                <p>{t('resource_usage')}</p>
              </div>
              <div className="text-nature">
                <p>{t('the_worldview_of_peoples')}</p>
              </div>
            </div>
          </div>
          <div className="line-nature">
            <img src={line} alt="" />
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Nature;
