import React from 'react';
import './AboutUs.css'
import Header from '../Header/header';
import Footer from '../Footer/footer';
import about_us from './../../images/about_us.jpg';
import line from './../../images/line.jpg';
import { useState } from 'react';
import {useTranslation} from "react-i18next";




function AboutUs() {

  const { t } = useTranslation();


  const [containerStyle5_1, setContainerStyle5_1] = useState({display: 'none'});
  // const [containerStyle5_2, setContainerStyle5_2] = useState({display: 'block'});
  
  const [containerStyle5_3, setContainerStyle5_3] = useState({display: 'none'});

  const [containerStyle5_5, setContainerStyle5_5] = useState({display: 'none'});



    const [isVisible1, setIsVisible1] = useState(false);
    const [isVisible2, setIsVisible2] = useState(false);
    const [isVisible3, setIsVisible3] = useState(false);
  
    const toggleVisibility1 = () => {
      setIsVisible1(!isVisible1);
    };

    const toggleVisibility2 = () => {
      setIsVisible2(!isVisible2);
    };

    const toggleVisibility3 = () => {
      setIsVisible3(!isVisible3);
    };




  return (
    <>
      <Header/>
      <div className='AboutUs'>
        <div className='centerBlock subsection1'>
          <div>
            <h1>{t('title1_about_us_page')}</h1>
          </div>
          <div>
            <p>{t('students')}</p>
          </div>
        </div>
        <div className='centerBlock subsection2'>
          <div className='subsec2-img'>
            <img src={about_us} alt="" />
          </div>
          <div>
            <div className='txt1'>
              <p> {t('about_us1')} <br/> {t('about_us2')} <br/> {t('about_us3')} <br/> {t('about_us4')} <br/> {t('about_us5')} <br/> {t('about_us6')} <br/> {t('about_us7')} <br/> {t('about_us8')} <br/> {t('about_us9')} <br/> {t('about_us10')} <br/> {t('about_us11')} <br/> {t('about_us12')} <br/> {t('about_us13')} </p>
            </div>
            <div className='txt1-mobile'>
              <p>{t('about_us_mobile')}</p>
            </div>
            <div className='team'>
              <p>{t('team_russia_native')}</p>
            </div>
          </div>
        </div>
        <div className='centerBlock subsection3'>
          <div className='header2'>
            <p>{t('title2_about_us_page')}</p>
          </div>
          <div className='line-decoration'>
            <img src={line} alt="" />
            <img src={line} alt="" />
          </div>
          <div className='subtitle'>
            <div>
              <p>{t('subtitle_title2_about_us')}</p>
            </div>
          </div>
          <div>
            <p>{t('nature_as_a_subject')}</p>
          </div>
        </div>
        <div className='centerBlock subsection4'>
          <div className='innerCenterBlock'>
            <div className='faq-first'>
              <h4>faq</h4>
            </div>
            <div className='faq-second'>
              <div className='common-block'>
                <div className='options' onClick={() => {setContainerStyle5_1({display: 'block'}); toggleVisibility1(); }}>
                  <p>{t('help_organization')}</p>
                  <div className='plus'>
                    {!isVisible1 && <div className='vertical-line1'></div>}
                    <div className='horizontal-line'></div>
                  </div>
                </div>
                <div className='line-sub4'>
                  <img src={line} alt="" />
                </div>
                <div>
                  {isVisible1 && <div className='return' style={containerStyle5_1}>
                    <p>{t('support')}</p>
                  </div>}
                </div>
              </div>
              <div className='common-block'>
                <div className='options' onClick={() => {setContainerStyle5_3({display: 'block'}); toggleVisibility2(); }}>
                  <p>{t('your_help')}</p>
                  <div className='plus'>
                    {!isVisible2 && <div className='vertical-line2'></div>}
                    <div className='horizontal-line'></div>
                  </div>
                </div>
                <div className='line-sub4'>
                  <img src={line} alt="" />
                </div>
                <div className='p-decoration'>
                  {isVisible2 && <div className='return' style={containerStyle5_3}>
                    <p>{t('contributions')}</p>
                  </div>}
                </div>
              </div>
              <div className='common-block'>
                <div className='options' onClick={() => {setContainerStyle5_5({display: 'block'}); toggleVisibility3(); }}>
                  <p>{t('about_connection')}</p>
                  <div className='plus'>
                    {!isVisible3 && <div className='vertical-line3'></div>}
                    <div className='horizontal-line'></div>
                  </div>
                </div>
                <div className='line-sub4'>
                  <img src={line} alt="" />
                </div>
                <div className='p-decoration'>
                  {isVisible3 && <div className='return' style={containerStyle5_5}>
                    <p> {t('gmail')} </p>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default AboutUs;
