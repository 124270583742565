import React from 'react';
import { useTranslation } from 'react-i18next';
import {Link, NavLink} from "react-router-dom"; // Импортируйте useTranslation
import './section3.css';

import people_wide from './../../../images/people-wide.jpg'
import people from './../../../images/people.jpg'
import game_wide from './../../../images/game-wide.jpg'
import game from './../../../images/game.jpg'
import meeting_wide from './../../../images/meeting-wide.jpg'
import meeting from './../../../images/meeting.jpg'
import quotes_1 from './../../../images/quotes_1.svg'
import quotes_2 from './../../../images/quotes_2.svg'
import line from './../../../images/line.jpg'
import zeroOne from './../../../images/01.svg'
import zeroTwo from './../../../images/02.svg'
import zeroThree from './../../../images/03.svg'


const Section3 = () => {
    const { t } = useTranslation();

    return (  
        <div>
            <div className='content-box'>
                <div className='content1'>
                    <div className='unifying1'>
                        <div className='text-box1'>
                            <p className='desktop-text'>{t('indigenous_small_numbered_people_definition')}</p>
                            <div className='mobile-text1'>
                                <p>{t('indigenous_small_numbered_people_definition')}</p>
                            </div>
                            <NavLink to="/peoples">{t('learn_more')}</NavLink>
                        </div>
                        <div className='box-img1'>
                            <img srcSet={`${people_wide} 1560w`} alt="" />
                        </div>
                    </div>
                </div>
                <div className='content2'>
                    <div className='unifying2'>
                        <div className='box-img1'>
                            <img srcSet={`${game_wide} 1560w`} alt="" />
                        </div>
                        <div className='text-box1'>
                            <p className='desktop-text'>{t('game_description')}</p>
                            <div className='mobile-text2'>
                                <p>{t('game_description_mobile')}</p>
                            </div>
                            <div className='a-content2'>
                                <NavLink to="/peoples">{t('learn_more')}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content3'>
                    <div className='unifying3'>
                        <div className='text-box1'>
                            <p className='desktop-text'>{t('indigenous_peoples_challenges')}</p>
                            <div className='mobile-text3'>
                                <p>{t('indigenous_peoples_challenges_mobile')}</p>
                            </div>
                            <div className='mobile-a-3'>
                                <NavLink to="/peoples">{t('learn_more')}</NavLink>
                            </div>
                            <div className='desktop-text'>
                                <NavLink to="/peoples">{t('learn_more')}</NavLink>
                            </div>
                        </div>
                        <div className='box-img1'>
                            <img srcSet={`${meeting_wide} 1560w`} alt="" />
                        </div>
                    </div>
                </div>
                <div className='content4'>
                    <div className='unifying4'>
                        <div className='quotation'>
                            <div className='decoration1'>
                                <div className='line-box'>
                                    <img src={line} alt="" />
                                </div>
                                <img srcSet={`${quotes_1} 1450w`} alt="" />
                            </div>
                            <div className='decoration2'>
                                <img srcSet={`${quotes_2} 1450w`} alt="" />
                                <div className='line-box'>
                                    <img src={line} alt="" />
                                </div>
                            </div>
                            <div className='text-box2 desktop-text'>
                                {/* <div className='text-box-outer box-outer1'>
                                    <div className='text-box-inner'>
                                        <p className='p1'>{t('indigenous_teaching_quote1')}</p>
                                    </div>
                                </div>
                                <div className='text-box-outer'>
                                    <div className='text-box-inner'>
                                        <p className='p2'>{t('indigenous_teaching_quote2')}</p>
                                    </div>
                                </div>
                                <div className='text-box-outer'>
                                    <div className='text-box-inner'>
                                        <p className='p3'>{t('indigenous_teaching_quote3')}</p>
                                    </div>
                                </div> */}
                                <div className="quote0">
                                    <p>{t('indigenous_teaching_quote0')}</p>
                                </div>
                                <div className='text-box-outer'>
                                    <div className='text-box-inner'>
                                        <p className='author'>{t('author_arseny_karsakov')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='mobile-text4'>
                                <div className='text4-1'>
                                    <p>{t('indigenous_teaching_quote0')}</p>
                                </div>
                                <div className='text4-2'>
                                    <p className='author'>{t('author_arseny_karsakov')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='content5'>
                    <div className='subheading'>
                        <div>
                            <p>{t('our_policy_title')}</p>
                        </div>
                    </div>
                    <div className='common-box'>
                        <div className='block1'>
                            <img srcSet={`${zeroOne} 1400w`} alt="" />
                            <div className='subcontainer'>
                                <p className='subcontainer-title'>{t('culture_preservation_title')}</p>
                                <div>
                                    <p>{t('culture_preservation_description')}</p>
                                </div>
                            </div>
                        </div>
                        <div className='block1'>
                            <img srcSet={`${zeroTwo} 1400w`} alt="" />
                            <div className="subcontainer">
                                <p className='subcontainer-title'>{t('educational_interaction_title')}</p>
                                <div>
                                    <p>{t('educational_interaction_description')}</p>
                                </div>
                            </div>
                        </div>
                        <div className='block1'>
                            <img srcSet={`${zeroThree} 1400w`} alt="" />
                            <div className="subcontainer">
                                <p className='subcontainer-title'>{t('website_information_title')}</p>
                                <div>
                                    <p>{t('website_information_description')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Section3;