import React from 'react';
import Header from "../Header/header";
import Footer from "../Footer/footer";
import './Peoples.css';


import WordTable from '../NationsTable/NationsTable';


import people_intro_1 from "../../images/people-intro-1.png"
import people_intro_2 from "../../images/people-intro-2.png"
import people_intro_3 from "../../images/people-intro-3.png"

import mountains from "../../images/people-mountains.png"

import people_nations_1 from "../../images/people-nations-1.png"
import people_nations_2 from "../../images/people-nations-2.png"
import people_nations_3 from "../../images/people-nations-3.png"
import people_nations_4 from "../../images/people_nations_4.png"
import people_nations_5 from "../../images/people_nations_5.png"
import people_nations_6 from "../../images/people_nations_6.png"

import people_activity_1 from "../../images/people_activity_01.png"
import people_activity_2 from "../../images/people_activity_02.png"
import people_activity_3 from "../../images/people_activity_03.png"
import people_activity_4 from "../../images/people_activity_04.png"

import {useTranslation} from "react-i18next";
import { t } from 'i18next';




const ActivityList = ({ activities }) => {
  
  return (
    <div className="activity-list">
      {activities.map((activity, index) => (
        <div className="list_unit" key={index}>
          <div>
            <p>{activity.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}<br/>
              </React.Fragment>
            ))}</p>
          </div>
          <img src={activity.image} alt={`activity-${index}`} />
        </div>
      ))}
    </div>
  );
};

function Peoples() {
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <section className="block intro">
        <h1>{t('title1_nations_page')}</h1>
        <div className="line line-1"></div>
        <img className="image1" src={`${people_intro_1}`} alt="main"/>
        <div className="text-block">
          <p>{t('nations_text1')}</p>
        </div>
        <img className="image2" src={`${people_intro_3}`} alt="people"/>
        <img className="image3" src={`${people_intro_2}`}alt="people"/>
      </section>

      <section className="block characteristics">
        <h2>{t('nations_features')}</h2>
        <div className="line line-1"></div>
        <div className="list list-1">
          <span className="list-number">1</span>
          <p>{t('nations_feature1')}</p>
        </div>
        <div className="list list-2">
          <span className="list-number">2</span>
          <p>{t('nations_feature2_1')}</p>
          <p>{t('nations_feature2_2')}</p>
        </div>
        <div className="list list-3">
          <span className="list-number">3</span>
          <p>{t('nations_feature3')}</p>
        </div>
        <div className="list list-4">
          <span className="list-number">4</span>
          <p>{t('nations_feature4')}</p>
        </div>
        <div className="line line-2"></div>
      </section>

      <section className="block small-nations">
        <h2>{t('title2_nations_page')}</h2>
        <div className="content">
            <div className="left">
              <div className="main">
                  <p><b>{t('title2_nations_page')}</b>{t('nations_definition1')}</p>
              </div>
              <div className="list">
                  <p className="title"><b>{t('nations_criteria')}</b></p>
                  <div className="items">
                      <p>{t('nations_criteria1')}</p>
                      <p>{t('nations_criteria2')}</p>
                      <p>{t('nations_criteria3')}</p>
                      <p>{t('nations_criteria4')}</p>
                  </div>
              </div>
            </div>
            <img className="right" src={`${mountains}`}/>
        </div>
        <div className="block nations-list">
            <p><b>{t('list_of_nations1')}</b></p>
            {/* <div className="columns">
                <p>Абазины<br/>Алеуты<br/>Алюторцы<br/>Бесермяне<br/>Вепсы<br/>Водь<br/>Долганы<br/>Ижоры<br/>Ительмены<br/>Камчадалы<br/>Кереки<br/>Кеты</p>
                <p>Коряки<br/>Кумандинцы<br/>Манси<br/>Нагайбаки<br/>Нанайцы<br/>Нганасаны<br/>Негидальцы<br/>Ненцы<br/>Нивхи<br/>Ороки<br/>Орочи</p>
                <p>Ханты<br/>Челканцы<br/>Чуванцы<br/>Чукчи<br/>Чулымцы<br/>Шапсуги<br/>Шорцы<br/>Эвенки<br/>Эвены<br/>Энцы<br/>Эскимосы<br/>Юкагиры</p>
                <p>Саамы<br/>Селькупы<br/>Сету<br/>Сойоты<br/>Тазы<br/>Теленгиты<br/>Телеуты<br/>Тофалары<br/>Тубалары<br/>Тувинцы-тоджинцы<br/>Удэгейцы<br/>Ульчи</p>
            </div> */}
            <WordTable rows={12} cols={4} nations={'small_nations'}></WordTable>
        </div>
        <div className="note">
            <p>{t('smallest_nations')}</p>
        </div>
        <div className="gallery">
            <img src={`${people_nations_1}`}/>
            <img src={`${people_nations_2}`}/>
            <img src={`${people_nations_3}`}/>
        </div>
        <div className="line line-1"></div>
      </section>

      <section className="block large-nations">
        <h2>{t('title3_nations_page')}</h2>
        <div className="text-block">
          <p>{t('nations_definition2')}</p>
        </div>
        <div className="nations-list">
          <p><b>{t('list_of_nations2')}</b></p>
          {/* <div className="columns">
            <p>Буряты <br/>Чукчи <br/>Якуты <br/>Балкарцы <br/>Чеченцы<br/>Черкесы <br/>Ингуши <br/>Карачаевцы <br/>Осетины <br/>Карелы</p>
            <p>Коми <br/>Ненцы <br/>Алтай <br/>Хакасы <br/>Тувинцы <br/>Агулы <br/>Аварцы <br/>Чеченцы-ауховцы<br/>Азербайджанцы в Дагестане <br/>Даргинцы</p>
            <p>Кумыки<br/>Лакцы <br/>Лезгины <br/>Ногайцы <br/>Этнические русские <br/>Рутульцы <br/>Табасаранцы <br/>Таты <br/>Цахуры <br/>Крымские татары</p>
            <p>Калмыки <br/>Ханты <br/>Манси <br/>Башкиры <br/>Чуваши <br/>Марийцы <br/>Мордва <br/>Татары <br/>Удмурты</p>
          </div> */}
          <WordTable rows={10} cols={4} nations={'large_nations'}></WordTable>
        </div>
        <div className="gallery">
            <img src={`${people_nations_4}`}/>
            <img src={`${people_nations_5}`}/>
            <img src={`${people_nations_6}`}/>
        </div>
        <div className="line line-1"></div>
      </section>

      <section className="block activity">
        <h2>{t('types_of_activities2')}</h2>
        <div className="text-block">
          <p>{t('nations_text2')}</p>
        </div>
        <ActivityList activities={activities}/>
      </section>
      <Footer/>
    </>
  );
}

const activities = [
  {
    text: t('activity_card1'),
    image: `${people_activity_1}`
  },
  {
    text: t('activity_card2'),
    image: `${people_activity_2}`
  },
  {
    text: t('activity_card3'),
    image:  `${people_activity_3}`
  },
  {
    text: t('activity_card4'),
    image: `${people_activity_4}`
  }
];

export default Peoples;