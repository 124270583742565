import React from 'react';
import Header from '../Header/header';
import Footer from '../Footer/footer';
import './Interviews.css';
import traveller from './../../images/Netflix-Life-4-2.jpg';
import deer from './../../images/feeds-the-deer.jpg';
import landscape3 from './../../images/landscape3.jpg';
import shack from './../../images/shack.jpg';
import sitting from './../../images/sitting_in_a_row.jpg';
import line from './../../images/line.jpg';
import {useTranslation} from "react-i18next";

function Interviews() {
  const { t } = useTranslation();

  return (
    <>
      <Header/>
      <div className='interviews-content'>
        <div className='interview-section-1'>
          <div className='interview-box-1_1'>
            <p>{t('title0_interviews_page')}</p>
            <div className='line-div'>
              <img src={line} alt="" />
              <img src={line} alt="" />
            </div>
          </div>
          <div className='interview-box-1_2'>
            <div><img srcSet={`${traveller} 1920w`} /></div>
            <div>
              <p>{t('about_the_employee')}</p>
            </div>
          </div>
        </div>
        <div>
          <div className='interview-box-2_1'>
            <div>
              <div className='txt1-interviews'>
                <h2>{t('title1_interviews_page')}</h2>
                <p>{t('types_of_activities1')}</p>
              </div>
              <img className='deer' srcSet={`${deer} 1920w`} />
              <div className='interview-box-2_2'>
                <h2>{t('title2_interviews_page')}</h2>
                <p>{t('relationship_with_nature')}</p>
              </div>
              <img className='landscape3' src={landscape3} alt="" />
              <img className='sitting' src={sitting} alt="" />
            </div>
            <div>
              <div className='interview-box-2_3'>
                <h2>{t('title3_interviews_page')}</h2>
                <p>{t('problems_of_peoples')}</p>
              </div>
              <img className='shack' srcSet={`${shack} 1920w`} />
            </div>
          </div>
        </div>
        <div className='interview-box-3_1'>
          <h2>{t('conclusion')}</h2>
          <div className='line-div'>
            <img className='line-width' src={line} alt="" />
            <img src={line} alt="" />
          </div>
          <div>
            <p>{t('debriefing')}</p>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Interviews;
